.component,
.componentArticle {
  padding-top: 10vh;
  padding-top: min(10vh, var(--size-100));

  & > * {
    margin-top: min(2vh, var(--size-20));
  }

  & > .heading {
    margin-top: min(7.5vh, var(--size-75));
  }

  & > .subheading {
    margin-top: min(3vh, var(--size-30));
  }
}

.componentArticle {
  & > .quote,
  & > .image {
    margin-top: 7.5vh;
    margin-top: min(7.5vh, var(--size-75));
    margin-bottom: 7.5vh;
    margin-bottom: min(7.5vh, var(--size-75));
  }
}

.componentBase {
  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.componentParagraph {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-20);
  line-height: var(--line-height-text);
}

.componentLink {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-20);
  line-height: var(--line-height-text);
  color: var(--color-gray-600);
}

.componentOrderedList {
  list-style-type: decimal;
  list-style-position: inside;
  line-height: var(--line-height-text);
}

.componentUnorderedList {
  list-style-type: disc;
  list-style-position: inside;
  line-height: var(--line-height-text);
}
