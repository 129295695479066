.component {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;

  & > .readTime {
    order: -2;
    margin-bottom: var(--size-20);
  }

  & > .image {
    order: -1;
    z-index: -1;
    margin-bottom: var(--size-20);
  }

  & > .content {
    &.relativeToParent {
      position: static;
    }
  }
}

.content {
  position: relative;

  & > .text {
    &.relativeToParent {
      position: static;
    }
  }
}

.text {
  position: relative;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.lineClamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.image {
  transition: var(--duration-1000) transform var(--ease-out-back-in);

  & > * {
    width: 100%;
  }

  &.isHovered {
    transform: rotate(-3deg);
  }
}
