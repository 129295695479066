.component {
  display: flex;
  align-items: center;
  position: relative;
  padding: var(--size-20) 0;
  font-family: var(--font-family-secondary);
  font-weight: 400;
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-28);

  &.relativeToParent {
    position: static;
  }

  & > .heading {
    &.relativeToParent {
      position: static;
    }
  }

  & > .icon {
    flex-shrink: 0;
    order: -1;
    margin-right: var(--size-15);
    width: 5rem;
    height: 5rem;

    @media (--viewport-lg) {
      width: 7rem;
      height: 7rem;
    }
  }
}

.icon {
  border: 2px solid rgba(var(--rgb-white), 0.4);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color var(--duration-250) var(--linear),
    color var(--duration-250) var(--linear),
    border var(--duration-250) var(--linear);

  &.isHovered,
  &.isFocused,
  &.isEntered {
    background-color: var(--background-enter);
    color: var(--color-enter);
  }

  & > * {
    width: 20px;

    @media (--viewport-lg) {
      width: auto;
    }
  }
}

.heading {
  position: relative;

  &.isHovered,
  &.isFocused,
  &.isEntered {
    text-decoration: underline;
  }

  & > .link {
    &.relativeToParent {
      position: static;
    }
  }
}

.link {
  position: relative;
  overflow-wrap: break-word;

  &.isHovered {
    text-decoration: underline;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
