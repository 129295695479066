@value _breakpointSm: 30em;
@value _breakpointMd: 48em;
@value _breakpointLg: 64em;
@value _breakpointXl: 80em;
@value _touch: (pointer: coarse);
@value _mouse: (pointer: fine);

:root {
  --breakpoint-sm: _breakpointSm;
  --breakpoint-md: _breakpointMd;
  --breakpoint-lg: _breakpointLg;
  --breakpoint-xl: _breakpointXl;
  --touch: _touch;
  --mouse: _mouse;
}

:export {
  viewportMd: screen and (min-width: 48em); /* 768px */
  viewportSm: screen and (min-width: 30em); /* 480px */
  breakpointSm: _breakpointSm;
  breakpointMd: _breakpointMd;
  breakpointLg: _breakpointLg;
  breakpointXl: _breakpointXl;
  touch: _touch;
  mouse: _mouse;
}

@custom-media --viewport-sm screen and (min-width: 30em); /* 480px */
@custom-media --viewport-md screen and (min-width: 48em); /* 768px */
@custom-media --viewport-lg screen and (min-width: 64em); /* 1024px */
@custom-media --viewport-xl screen and (min-width: 80em); /* 1280px */
@custom-media --viewport-mobile screen and (max-width: 30em);
@custom-media --touch (pointer: coarse); /* input is touch */
@custom-media --mouse (pointer: fine); /* input is a mouse */
