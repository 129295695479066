.component {
  position: relative;

  & > .icons {
    position: absolute;
    top: calc(55px + var(--size-10));
    left: 0;
  }
}

.icons {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: var(--size-10);
  }
}
