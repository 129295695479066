.component {
  padding-top: var(--size-90);

  & > :not(:last-child) {
    margin-bottom: var(--size-75);

    @media (--viewport-md) {
      margin-bottom: var(--size-100);
    }
  }
}
