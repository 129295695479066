.componentHeroSection {
  padding: 7.5vh 0;
  padding: min(7.5vh, var(--size-75)) 0;

  & > :not(:last-child) {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-bottom: var(--size-75);
    }
  }
}

.componentJongeNotarissenDoenEenBoekjeOpen,
.componentOpDeHoogteBlijvenOverHetVak,
.componentNieuwsUitHetNotariaatSection {
  padding: 7.5vh 0;
  padding: min(7.5vh, var(--size-75)) 0;
}

.componentJongeNotarissenDoenEenBoekjeOpen {
  position: relative;
  overflow: hidden;

  & > .decoration {
    position: absolute;
    top: 7.5vh;
    top: min(7.5vh, var(--size-50));
    right: -3vw;
    width: 40vw;
    max-width: 496px;
  }
}
