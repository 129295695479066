.component {
  display: grid;
  grid-column-gap: var(--size-10);
  grid-template-columns: auto 1fr;
  grid-template-areas: 'star .... ....'
    '.... text text'
    '.... button button';

  & > .star {
    width: 30px;
    grid-area: star;
  }

  & > .text {
    margin-bottom: var(--size-20);
    grid-area: text;
    max-width: 30ch;
  }

  & > .button {
    grid-area: button;
  }
}

.text {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-16);
  font-size: var(--font-size-16-18);
}
