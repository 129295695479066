.componentBase {
  padding: var(--size-10) var(--size-15);
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-16);
  font-size: var(--font-size-14-16);
  border-radius: 32px;
  line-height: 1;
  display: inline-flex;
  gap: var(--size-6);
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: background-color var(--duration-250) var(--linear),
    color var(--duration-250) var(--linear),
    border var(--duration-250) var(--linear);

  @media (--viewport-md) {
    padding: var(--size-15) var(--size-30);
  }
}

.componentPrimary {
  color: var(--color-primary-variant);
  border: 2px solid var(--color-on-primary-variant);

  &:hover,
  &:focus {
    background-color: transparent;
    color: var(--color-on-primary-variant);
    border-color: var(--color-on-primary-variant);
  }
}

.componentGhost {
  border-width: 2px;
  border-style: solid;

  &:hover,
  &:focus {
    color: var(--color-enter);
    background-color: var(--background-enter);
    border-color: var(--border-color-enter);
  }
}

.componentCircularGhost {
  padding: 0;
  width: 55px !important;
  height: 55px !important;
  border-width: 2px;
  border-style: solid;

  &:hover,
  &:focus {
    color: var(--color-enter);
    background-color: var(--background-enter);
    border-color: var(--border-color-enter);
  }
}

.componentLink {
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
