.component {
  & > .containerText {
    margin-top: var(--size-45);

    @media (--viewport-lg) {
      margin-top: calc(-1 * var(--size-120));
    }
  }

  & > .content {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-bottom: var(--size-75);
    }
  }
}

.videos {
  @media (--viewport-md) {
    column-count: 2;
    column-gap: var(--size-90);
  }

  & > :not(:first-child) {
    margin-top: var(--size-35);

    @media (--viewport-md) {
      margin-top: var(--size-120);
    }
  }
}

.componentVideo {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  align-items: center;

  & > .button {
    z-index: 1;

    &.relativeToParent {
      position: static;
    }
  }

  & > .image {
    order: -1;
  }

  & > .arrows {
    margin-right: -100px;
    position: absolute;
    top: -14%;
    width: 75px;

    @media (--viewport-sm) {
      width: 100px;
    }

    @media (--viewport-lg) {
      width: auto;
    }
  }
}

.arrows {
  transition: var(--duration-150) opacity var(--ease-out-expo),
    var(--duration-150) transform var(--ease-out-expo);
  opacity: 0;

  &.isHover {
    opacity: 1;
    transform: translate(-6px, 6px);
  }
}

.image {
  transition: var(--duration-1000) transform var(--ease-out-back-in);

  &.isHover {
    transform: rotate(-3deg);
  }
}

.componentVideoModal {
  position: relative;

  & > .questionnaire {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
