.component {
  position: relative;
  display: grid;

  @media (--viewport-md) {
    grid-template-columns: 1fr minmax(auto, 55ch);
  }

  & > .imageLayout {
    grid-column: 1 / -1;
    grid-row: 1;

    @media (--viewport-md) {
      min-height: 70rem;
    }
  }

  & > * {
    min-width: 0;
  }

  & > .content {
    position: relative;
    grid-column: 1 / -1;
    grid-row: 1 / -1;

    @media (--viewport-md) {
      grid-column: 2;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: var(--size-30);

  @media (--viewport-md) {
    justify-content: flex-start;
    padding: var(--size-90) var(--size-50) var(--size-120);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-15);

    @media (--viewport-md) {
      margin-bottom: var(--size-30);
    }
  }
}

.imageLayout {
  position: relative;

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.image {
  transition: var(--duration-1000) opacity linear,
    var(--duration-2000) transform var(--ease-out-back-in);
  transform: rotate(2deg) translateY(40px);
  opacity: 0;

  &.wasInViewport {
    opacity: 1;
    transform: rotate(-1deg) translateY(0);
  }
}
