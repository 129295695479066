:root {
  --linear: linear;
  --ease-out-back-in: cubic-bezier(0.23, 1.76, 0.58, 0.87);
  --ease-out-expo: cubic-bezier(0.16, 1, 0.3, 1);
  --duration-150: 0.15s;
  --duration-250: 0.25s;
  --duration-1000: 1s;
  --duration-1500: 1.5s;
  --duration-2000: 2s;
}
