._rootComponent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
  pointer-events: none;
  margin: auto;

  & > * {
    pointer-events: auto;
    height: 100%;
  }
}

.component {
  position: relative;
  z-index: 0;
  display: flex;
  color: var(--color-white);

  & > .backdrop {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .inner {
    width: 100%;
    margin: auto;

    @media (--viewport-md) {
      width: 90%;
    }

    @media (--viewport-lg) {
      width: 80%;
    }
  }
}

.inner {
  background-color: black;
  animation: rotatein var(--duration-1000) var(--ease-out-expo) forwards;
  transform-origin: bottom left;
  position: relative;
  z-index: 0;

  & > * {
    height: 100%;
  }

  & > .close {
    position: absolute;
    right: var(--size-10);
    top: var(--size-10);
    z-index: 1;
    width: 16px;
    height: 16px;

    @media (--viewport-md) {
      right: var(--size-30);
      top: var(--size-30);
      width: 20px;
      height: 20px;
    }
  }
}

.backdrop {
  background-color: var(--color-gray-900);
}

@keyframes rotatein {
  from {
    transform: rotate(-5deg) scaleX(0.4) scaleY(0.6) skew(5deg);
    opacity: 0;
  }
}

.componentCloseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after {
    content: '';
    height: 50px;
    width: 50px;
    position: absolute;
  }
}
