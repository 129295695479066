.component {
  padding-top: var(--size-30);

  @media (--viewport-md) {
    padding-top: var(--size-75);
  }

  & > .infoBlock {
    margin-top: var(--size-30);

    @media (--viewport-md) {
      margin-top: calc(-1 * var(--size-100));
    }
  }
}

.videos {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .video {
    &:hover,
    &:focus {
      z-index: 3;
    }
  }

  & > .firstVideo {
    margin-top: calc(-1 * var(--size-35));
    margin-left: 2rem;
    z-index: 1;
    width: 100%;
    height: 100%;

    @media (--viewport-md) {
      margin-top: 0;
      margin-left: 0;
      width: calc(100% * (550 / 1380));
    }
  }

  & > .secondVideo {
    order: -1;
    z-index: 2;
    width: 100%;

    @media (--viewport-md) {
      order: 0;
      position: absolute;
      top: calc(-1 * var(--size-30));
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 1;
      width: calc(100% * (578 / 1380));
    }
  }

  & > .thirdVideo {
    margin-top: calc(-1 * var(--size-25));
    z-index: 0;
    width: 100%;
    height: 100%;

    @media (--viewport-md) {
      margin-top: 0;
      z-index: 0;
      width: calc(100% * (550 / 1380));
    }
  }
}

.video {
  transition: var(--duration-1000) transform var(--ease-out-back-in);
}

.firstVideo {
  &:hover,
  &:focus {
    transform: rotate(-2deg) scale(1.1);
  }
}

.secondVideo {
  transform: rotate(-4deg);

  @media (--viewport-md) {
    transform: rotate(4deg);
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}

.thirdVideo {
  &:hover,
  &:focus {
    transform: rotate(2deg) scale(1.1);
  }
}

.aspectRatio {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    height: 0;
  }

  &.aspectRatioFirst {
    &::before {
      padding-bottom: calc(100% / (299 / 318));

      @media (--viewport-md) {
        padding-bottom: calc(100% / (550 / 470));
      }
    }
  }

  &.aspectRatioSecond {
    &::before {
      padding-bottom: calc(100% / (330 / 248));

      @media (--viewport-md) {
        padding-bottom: calc(100% / (578 / 400));
      }
    }
  }

  &.aspectRatioThird {
    &::before {
      padding-bottom: calc(100% / (315 / 235));

      @media (--viewport-md) {
        padding-bottom: calc(100% / (462 / 596));
      }
    }
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.componentVideo {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  & > .button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > .image {
    height: 100%;
    width: 100%;
    order: -1;
  }
}

.button {
  padding: 0 var(--size-20);
  display: flex;
  align-items: flex-end;
}

.image {
  object-fit: cover;
}
