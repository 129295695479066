.componentButtonSection {
  padding: min(7.5vh, var(--size-75)) 0;
}

.buttons {
  display: flex;
  gap: var(--size-20);
}

.links {
  display: flex;
  gap: var(--size-20);
}
