:root {
  --color-gray-100: #fefefe;
  --color-gray-400: #eae8e1;
  --color-gray-600: #858585;
  --color-gray-900: #343434;
  --color-green-400: #a6b185;
  --color-pink-400: #f8beb6;
  --color-blue-400: #405183;
  --color-white: #fefefe;

  --rgb-white: 254, 254, 254;
}
