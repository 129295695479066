.cookiebar {
  border-radius: 25px;
  padding: var(--size-30);

  @media (--viewport-sm) {
    padding: var(--size-40);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);

    @media (--viewport-sm) {
      margin-bottom: var(--size-25);
    }
  }
}

.text {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-14);
  font-size: var(--font-size-14-16);
}

.buttons {
  display: flex;
}
