.componentBase {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-indent: initial;
  position: relative;
  z-index: 0;

  & > .sprite {
    position: absolute;
    z-index: -1;
    margin: 0 auto;
    width: 100%;
    height: 0;
  }
}

.sprite {
  background-position: 0 0;
  display: block;
}

.componentStripe {
  & > .sprite {
    top: 100%;
  }
}

.totalSprite {
  padding-bottom: calc(100% / (614 / 404));
  transform: scale(1.3) translateY(1rem);
  background-size: 1900%;
  animation: totalSprite 3s steps(19) infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none !important;
  }
}

@keyframes totalSprite {
  100% {
    background-position: 1900% 0;
  }
}

.stripeSprite {
  padding-bottom: calc(100% / (564 / 77));
  transform: scale(1.2);
  background-size: 600%;
  animation: stripeSprite 1s steps(6) infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none !important;
  }
}

@keyframes stripeSprite {
  100% {
    background-position: 600% 0;
  }
}

.circleSprite {
  padding-bottom: calc(100% / (540 / 170));
  transform: scale(1.3);
  background-size: 500%;
  animation: circleSprite 1s steps(5) infinite;

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none !important;
  }
}

@keyframes circleSprite {
  100% {
    background-position: 500% 0;
  }
}
