.component {
  font-family: var(--font-family-primary);
  font-weight: 600;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > .image {
    margin-bottom: var(--size-20);
  }

  & > .quote {
    margin: 0;
    margin-bottom: var(--size-15);
    max-width: 24ch;
  }
}

.quote {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-30);
  text-align: center;
}
