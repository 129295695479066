.component {
  background-color: var(--color-gray-900);
  padding: var(--size-45) 0;
  color: var(--color-white);
  font-family: var(--font-family-primary);
}

.layout {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .logo {
    margin-top: var(--size-30);

    @media (--viewport-md) {
      margin-left: var(--size-25);
      margin-top: 0;
    }
  }

  & > .socials {
    order: -1;
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      order: 0;
      margin-bottom: 0;
      margin-left: auto;
    }
  }
}

.links {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  gap: var(--size-10) var(--size-25);
}

.logo {
  display: flex;
  justify-content: center;
  opacity: 0.4;
}

.socials {
  display: flex;

  & > :not(:last-child) {
    margin-right: var(--size-15);
  }
}

.componentSocialIcon {
  border: 2px solid rgba(var(--rgb-white), 0.2);
  border-radius: 50%;
  padding: var(--size-15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color var(--duration-250) var(--linear),
    color var(--duration-250) var(--linear),
    border var(--duration-250) var(--linear);

  &:hover,
  &:focus {
    background-color: var(--color-white);
    color: var(--color-gray-900);
  }
}
