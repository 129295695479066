.componentBase {
  display: flex;
  justify-content: center;
  padding: 0 var(--gutter);
  padding: 0 var(--gutter-responsive);

  & > .layout {
    width: 100%;
  }
}

.componentSm {
  & > .layout {
    max-width: var(--size-container-max-width-sm);
  }
}

.componentMd {
  & > .layout {
    max-width: var(--size-container-max-width-md);
  }
}

.componentLg {
  & > .layout {
    max-width: var(--size-container-max-width-lg);
  }
}

.componentXl {
  & > .layout {
    max-width: var(--size-container-max-width-xl);
  }
}
