.componentBase {
  font-family: var(--font-family-primary);
  line-height: 1;
  letter-spacing: -0.003em;
  font-weight: 600;
}

.componentLg {
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-80);
}

.componentMd {
  font-size: var(--font-size-30);
  font-size: var(--font-size-30-50);
}

.componentSm {
  font-size: var(--font-size-25);
  font-size: var(--font-size-25-30);
}
