.component {
  position: relative;
  z-index: 0;

  & > .decoration {
    top: 30%;
    right: 0;
    position: absolute;
    width: 200px;
    z-index: -1;

    @media (--viewport-md) {
      top: 5%;
      right: 15%;
      width: 250px;
    }
  }
}

.articles {
  display: grid;
  grid-gap: var(--size-75);

  @media (--viewport-lg) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--size-120);
    align-items: center;
  }
}
