.component {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: var(--size-30);
  grid-row-gap: var(--size-20);
  grid-template-areas:
    'readingTime   readingTime   readingTime'
    'heading       heading       heading'
    'share         metaData      metaData';

  & > .readTime {
    grid-area: readingTime;
  }

  & > .heading {
    margin-bottom: var(--size-20);
    grid-area: heading;
  }

  & > .metaData {
    grid-area: metaData;
    align-self: center;
  }

  & > .share {
    grid-area: share;
  }
}
