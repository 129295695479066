.overview {
  padding: 7.5vh 0;
  padding: min(7.5vh, var(--size-75)) 0;
}

.content {
  & > :not(:last-child) {
    margin-bottom: var(--size-50);

    @media (--viewport-md) {
      margin-bottom: var(--size-120);
    }
  }
}
