
.componentPrimaryBase {
  font-family: var(--font-family-primary);
  line-height: var(--line-height-text);
  letter-spacing: -0.007em;
}

.componentPrimaryLg {
  font-size: var(--font-size-22);
  font-size: var(--font-size-22-30);
}

.componentPrimaryMd {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-25);
}

.componentSecondaryBase {
  font-family: var(--font-family-secondary);
  line-height: var(--line-height-text);
  letter-spacing: -0.01em;
}

.componentSecondaryLg {
  font-size: var(--font-size-26);
  font-size: var(--font-size-26-30);
}

.componentSecondaryMd {
  font-size: var(--font-size-20);
  font-size: var(--font-size-18-24);
}

.componentSecondarySm {
  font-size: var(--font-size-18);
}
