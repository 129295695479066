.page {
  display: flex;
  flex-direction: column;

  & > * {
    flex-grow: 1;
  }
}

.content {
  padding: 7.5vh 0;
  padding: min(7.5vh, var(--size-75)) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & > :not(:last-child) {
    margin-bottom: var(--size-75);
  }
}
