.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & > .videoWrapper {
    width: 100%;
  }
}

.videoWrapper {
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (1 / 1));
    height: 0;

    @media (--viewport-sm) {
      padding-bottom: calc(100% / (16 / 9));
    }
  }

  & > .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important; /* Prevent override by react-player */
    height: 100% !important; /* Prevent override by react-player */
  }
}
