.componentBase {
  font-family: var(--font-family-secondary);
  line-height: 1;
  padding-left: 0.6em;
  text-indent: -0.6em;
  letter-spacing: -0.00175em;
  font-weight: 400;
  display: block;
}

.noIndent {
  padding-left: 0;
  text-indent: 0;
}

.alternateFont {
  font-family: var(--font-family-primary);
  font-weight: 600;
}

.componentXl {
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-100);
}

.componentLg {
  font-size: var(--font-size-45);
  font-size: var(--font-size-45-80);
}

.componentMd {
  font-size: var(--font-size-20);
  font-size: var(--font-size-20-48);
}
