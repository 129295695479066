.component {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);

  &::before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (1 / 1));
    height: 0;

    @media (--viewport-sm) {
      padding-bottom: calc(100% / (16 / 9));
    }
  }

  & > .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--size-30);
  text-align: center;

  @media (--viewport-md) {
    padding: var(--size-80);
  }
}

.inner {
  & > .question {
    margin: 0 auto var(--size-30);
    max-width: 60ch;

    @media (--viewport-md) {
      margin-bottom: var(--size-50);
    }
  }

  & > .answers {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-bottom: var(--size-50);
    }
  }
}

.componentAntwoorden {
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0 auto;
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.componentAnswer {
  padding: var(--size-10) var(--size-20);
  border-radius: 100px;
  border: 1px solid var(--color-white);
  font-size: var(--font-size-12);
  font-size: var(--font-size-12-22);
  font-family: var(--font-family-secondary);
  transition: background-color var(--duration-250) var(--linear),
    color var(--duration-250) var(--linear);

  @media (--viewport-md) {
    padding: var(--size-20) var(--size-30);
  }

  &:hover,
  &:focus {
    background-color: var(--color-white);
    color: var(--color-gray-900);
  }
}

.componentFeedback {
  & > .star {
    margin: 0 auto;
  }

  & > .feedBackText {
    margin: 0 auto;
    max-width: 60ch;
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.feedBackText {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-24);
  font-family: var(--font-family-primary);
}
