.component {
  font-family: var(--font-family-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > .quote {
    margin: 0;
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-30);

    @media (--viewport-md) {
      margin-bottom: var(--size-20);
    }
  }
}

.quote {
  font-size: var(--font-size-30);
  font-size: var(--font-size-30-40);
  font-weight: 600;
}

.author {
  font-size: var(--font-size-15);
  position: relative;
  padding: var(--size-20) var(--size-40);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('/images/quoteAuthorBg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
  }
}
