.page {
  display: flex;
  flex-direction: column;

  & > * {
    flex-grow: 1;
  }
}

.content {
  padding: 7.5vh 0;
  padding: min(7.5vh, var(--size-75)) 0;
}
