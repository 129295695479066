.component {
  display: grid;
  grid-template-columns: minmax(var(--size-10), 1fr) auto minmax(var(--size-10), 1fr);

  & > * {
    min-width: 0;
  }

  & > .heading {
    margin-bottom: var(--size-60);
    grid-column: 2;
  }

  & > .text {
    justify-self: end;
    max-width: 38ch;
    grid-column: 1 / -1;

    @media (--viewport-md) {
      grid-column: 2 / -1;
    }
  }
}
