.component {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .content {
    width: 100%;
    z-index: 1;

    &.relativeToParent {
      position: static;
    }

    @media (--viewport-md) {
      width: 40%;
    }
  }

  & > .imageLayout {
    order: -1;
    width: 100%;
    height: auto;
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-right: var(--size-50);
      margin-bottom: 0;
      width: 50%;
    }
  }

  & > .outerDecoration {
    order: -2;
    width: 100%;
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      position: absolute;
      top: calc(-1 * var(--size-20));
      right: 0;
      order: 0;
      width: auto;
    }
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;

  & > .readingTime {
    margin-bottom: var(--size-20);
    order: -1;
    position: absolute;
    top: 50px;
    left: 0;

    @media (--viewport-md) {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  & > .text {
    &.relativeToParent {
      position: static;
    }
  }
}

.text {
  position: relative;

  & > :not(:last-child) {
    margin-bottom: var(--size-20);
  }
}

.lineClamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.aspectRatio {
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 0;
    padding-bottom: calc(100% / (3 / 2));

    @media (--viewport-md) {
      padding-bottom: calc(100% / (5 / 4));
    }
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.imageLayout {
  transition: var(--duration-1000) transform var(--ease-out-back-in);

  & > * {
    width: 100%;
  }

  &.isHovered {
    transform: rotate(-3deg);
  }
}

.outerDecoration {
  & > .decoration {
    margin-left: auto;
  }
}

.decoration {
  transform: rotate(15deg);
}
