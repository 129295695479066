:root {
  --font-family-base: 'Raleway', sans-serif;
  --font-family-primary: 'Circular', sans-serif;
  --font-family-secondary: 'Recoleta', serif;

  --font-size-unit: 1px;
  --line-height-text: 1.33;
  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1280;

  --line-height-heading: 1.2;

  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --font-size-12: 12px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-25: 25px;
  --font-size-26: 26px;
  --font-size-30: 30px;
  --font-size-45: 45px;

  --font-size-14-16: clamp(14px, calc(14px + 2 * var(--m)), 16px);
  --font-size-16-18: clamp(16px, calc(16px + 2 * var(--m)), 18px);
  --font-size-18-20: clamp(18px, calc(18px + 2 * var(--m)), 20px);
  --font-size-12-22: clamp(12px, calc(12px + 10 * var(--m)), 22px);
  --font-size-18-24: clamp(18px, calc(18px + 6 * var(--m)), 24px);
  --font-size-20-25: clamp(20px, calc(20px + 5 * var(--m)), 25px);
  --font-size-20-28: clamp(20px, calc(20px + 8 * var(--m)), 28px);
  --font-size-20-30: clamp(20px, calc(20px + 10 * var(--m)), 30px);
  --font-size-22-30: clamp(22px, calc(22px + 8 * var(--m)), 30px);
  --font-size-25-30: clamp(25px, calc(25px + 5 * var(--m)), 30px);
  --font-size-26-30: clamp(26px, calc(26px + 4 * var(--m)), 30px);
  --font-size-30-40: clamp(30px, calc(30px + 10 * var(--m)), 40px);
  --font-size-20-48: clamp(20px, calc(20px + 28 * var(--m)), 48px);
  --font-size-30-50: clamp(30px, calc(30px + 20 * var(--m)), 50px);
  --font-size-45-80: clamp(45px, calc(45px + 35 * var(--m)), 80px);
  --font-size-45-100: clamp(45px, calc(45px + 55 * var(--m)), 100px);
}
