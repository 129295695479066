.article {
  padding-top: 7.5vh 0;
  padding-top: min(7.5vh, var(--size-75));
  position: relative;
  z-index: 0;

  & > .articleHeader {
    margin-bottom: 7.5vh;
    margin-bottom: min(7.5vh, var(--size-75));
    position: relative;
    z-index: 1;
  }

  & > .headerImage {
    margin-bottom: var(--size-50);
  }

  & > .decoration {
    margin-left: auto;
    margin-right: auto;
  }
}

.decoration {
  padding: 10vh 0;
  padding: min(10vh, var(--size-100)) 0;
}

.componentOverviewSection {
  padding: 7.5vh 0;
  padding: min(7.5vh, var(--size-75)) 0;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentOverview {
  & > .heading {
    margin-bottom: var(--size-50);
  }
}
