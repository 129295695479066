.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--size-30) 0;

  & > .linkLogo {
    width: 85px;

    @media (--viewport-md) {
      width: 140px;
    }
  }
}
