.background {
  --background-enter: #fefefe;

  background-color: var(--color-pink-400);

  @media print {
    background-color: var(--color-white);
  }
}

.color {
  --color-enter: #343434;

  color: var(--color-gray-900);

  @media print {
    color: var(--color-gray-900);
  }
}

.border {
  --border-color-enter: #fefefe;

  border-color: var(--color-white);

  @media print {
    border-color: var(--color-gray-900);
  }
}

.accent {
  color: var(--color-gray-900);
}

.primary {
  --color-primary-variant: #fefefe;

  background-color: var(--color-gray-900);

  @media print {
    background-color: var(--color-gray-900);
  }
}

.onPrimary {
  --color-on-primary-variant: #343434;

  color: var(--color-white);

  @media print {
    color: var(--color-white);
  }
}
