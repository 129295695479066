/*
These units define a spacing rhythm.
Try to use this as much as possible for paddings and margins, as it gives consistency for the eye
*/

:root {
  --size-10: 10px;
  --size-15: 15px;
  --size-20: 20px;
  --size-25: 25px;
  --size-30: 30px;
  --size-35: 35px;
  --size-40: 40px;
  --size-45: 45px;
  --size-50: 50px;
  --size-60: 60px;
  --size-75: 75px;
  --size-80: 80px;
  --size-90: 90px;
  --size-100: 100px;
  --size-120: 120px;

  --gutter: 5vw;
  --gutter-responsive: max(5vw, var(--size-30));

  --size-container-max-width-sm: 800px;
  --size-container-max-width-md: 1160px;
  --size-container-max-width-lg: 1300px;
  --size-container-max-width-xl: 1380px;
}
